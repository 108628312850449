<template>
  <el-dialog
    class="weekly_plan"
    :visible="dialogVisible"
    width="58.33333vw"
    :before-close="dialogBeforeClose"
  >
    <div class="weekly_plan_body">
      <div class="weekly_plan_body_header">
        <div class="wpbh_grade">
          <p class="p">
            <img src="../../../assets/img/icon_weekly_plan_ grade.png"/>
            <span>{{classesName}}</span>
          </p>
        </div>
        <div class="wpbh_title">
          <p>{{date.week}} th Weekly Plan</p>
          <p>第 {{date.week}} 周周计划</p>
        </div>
        <div class="wpbh_info">
          <el-button @click="handlerButtonDownload" class="wpbh_info_download" type="primary" size="mini">下载保存</el-button>
          <p class="p">
            <img src="../../../assets/img/icon_weekly_plan_date.png"/>
            <span>{{date.year}}年第{{date.week}}周 ({{date.firstDate}}-{{date.lastDate}})</span>
          </p>
        </div>
      </div>

      <div class="weekly_table">
        <div class="weekly_table_header">
          <div class="weekly_table_item">
              <span>time</span>
              <span>时间</span>
          </div>
          <div v-for="week in weekData" :key="week.day" class="weekly_table_item">
            <span>{{week.eText}}</span>
            <span>{{week.text}}({{week.showDate}})</span>
          </div>
        </div>

        <div class="weekly_table_body">
          <div
            v-for="(weekLink, weekLinkIndex) in weekPlanData"
            :key="weekLinkIndex"
            class="weekly_table_column">
              <div class="weekly_table_item weekly_item_first">
                <span class="weekly_item_first_tiem">{{weekLink.startTime}}-{{weekLink.endTime}}</span>
                <p class="weekly_item_first_title">{{weekLink.processName}}</p>
              </div>
              <div class="weekly_table_item weekly_table_item_week">
                <template v-if="weekLink.children && weekLink.children.length">
                  <div
                    class="wtiw_row"
                    v-for="(children, childrenIndex) in weekLink.children"
                    :key="childrenIndex"
                  >
                    <div
                      v-for="(childrenItem, childrenItemIndex) in children"
                      :key="childrenItemIndex"
                      :class="'wtiw_row_item ' + (childrenItem ? 'wtiw_row_item_' + childrenItem.widthCount : '')"
                    >
                      <template
                        v-if="childrenItem"
                      >
                        <div class="wtiw_ri_course_name">{{childrenItem.courseName}}</div>
                        <div class="wtiw_ri_activity_name">{{childrenItem.activityName}}</div>
                      </template>
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div class="wtiw_row"></div>
                </template>
              </div>
          </div>
        </div>
      </div>

    </div>
  </el-dialog>
</template>

<script>
import html2canvas from 'html2canvas';
export default {
  props: {
    weeklyData: Object,
  },
  data() {
    return {
      dialogVisible: false,
      weekPlanData: [],
      templateData: {},
      classesName: '',
      date: {
        firstDate: '',
        lastDate: '',
        week: '',
        year: '',
      },
      weekData: [],
    };
  },
  created() {
  },
  methods: {
    handlerButtonDownload() {
      let dom = document.querySelector('.weekly_plan_body');
      html2canvas(dom, {
        scale: 2,
        width: dom.offsetWidth,
        height: dom.offsetHeight,
      }).then(canvas => {
        let cvs = document.body.appendChild(canvas, {
          removeContainer: true,
        });
        let jpg = cvs.toDataURL();
        let a = document.createElement('a');
        a.download = `周计划_${ this.weekData[0].showDate.replace('.', '_') }-${ this.weekData[6].showDate.replace('.', '_') }`;
        a.href = jpg;
        a.click();
        document.body.removeChild(cvs);
      });
    },
    dialogBeforeClose() {
      this.dialogVisible = false;
      this.weekPlanData = {};
    },
    /**
     * 周转数组下标
     * @param {string} weekDay
     */
    weekToNumber(weekDay) {
      let n = null;
      switch (weekDay) {
        case '1':
          n = 6;
          break;
        case '2':
          n = 0;
          break;
        case '3':
          n = 1;
          break;
        case '4':
          n = 2;
          break;
        case '5':
          n = 3;
          break;
        case '6':
          n = 4;
          break;
        case '7':
          n = 5;
          break;
      }
      return n;
    },
    /**
     * 数组长度排序
     * @param {array} columnData
     * @param {boolean} status true 从大到小，false 从小到大
     * @return {array}
     */
    handlerLinkDataSort(columnData, status) {
      columnData = columnData.sort((a, b) => {
        if (status) {
          return b.length - a.length;
        } else {
          return a.length - b.length;
        }
      });
      return columnData;
    },
    /**
     * 下面数据往上排
     * @param {array} columnData
     * @return {array}
     */
    handlerLinkDataComeUp(columnData) {
      columnData = this.handlerLinkDataSort([...columnData], true);
      columnData = this.handlerLinkDataSetLength(columnData);
      let columnDataCache = this.handlerLinkDataSort([...columnData], false);
      columnDataCache.shift();
      columnData.forEach((item, index) => {
        columnDataCache = columnDataCache.filter((cacheItem, cacheItemIndex) => {
          let flag = true;
          let cacheIndexs = [];
          let cacheIndexsChecked = [];
          cacheItem.forEach((cacheItem, index) => {
            if (cacheItem !== null) {
              cacheIndexs.push(index);
            }
          });

          cacheIndexs.forEach((cacheIndexItem) => {
            if (item[cacheIndexItem] === null) {
              cacheIndexsChecked.push(cacheIndexItem);
            }
          });
          if (cacheIndexs.length === cacheIndexsChecked.length) {
            cacheIndexs.forEach((cacheIndexItem) => {
              item[cacheIndexItem] = cacheItem[cacheIndexItem];
              cacheItem[cacheIndexItem] = null;
            });
            flag = false;
          }
          return flag;
        });
      });
      return this.handlerLinkDataDeleteRowNull(columnData);
    },
    /**
     * 测试显示周数
     * @param {array} children
     */
    handlerLinkDataTestWeek(children) {
      let arr = [];
      children.forEach((item, index) => {
        arr[index] = [];
        item.forEach((item1, index1) => {
          item1 && arr[index].push(item1.planDays);
        });
      });
      console.table(arr);
      return arr;
    },
    /**
     * 活动以周定位，空设为null
     * @param {array} data
     * @return {array}
     */
    handlerLinkDataSetLength(data) {
      let arr = [];
      data.forEach((item, index) => {
        arr[index] = Array(7).fill(null);
        item.forEach(item1 => {
          if (item1) {
            let i = this.weekToNumber(item1.planDays);
            arr[index][i] = item1;
          }
        });
      });
      return arr;
    },
    handlerLinkDataDeleteRowNull(data) {
      data = data.filter(item => {
        let nullCount = 0;
        let rowLength = item.length;
        item.forEach(item1 => {
          if (item1 === null) {
            nullCount++;
          }
        });
        if (rowLength === nullCount) {
          return false;
        }
        return true;
      });
      return data;
    },
    /**
     * 为周计划表数据设置长度
     */
    handlerLinkDataDeleteRepeat(data) {
      let columnData = [];
      data.forEach((item, index) => {
        columnData[index] = [];
        let dataArr = [];
        let widthCount = 0;
        for (let i = 0; i < item.length; i++) {
          let item1 = item[i];
          let nextItem = item[i + 1];
          widthCount ++;
          if (!item1 || !nextItem) {
            if (item1) {
              item1.widthCount = widthCount;
            }
            widthCount = 0;
            dataArr.push(item1);
            continue;
          }

          if (item1.uniteCode !== nextItem.uniteCode) {
            item1.widthCount = widthCount;
            widthCount = 0;
            dataArr.push(item1);
          }

        }
        columnData[index].push(...dataArr);
        return item;
      });
      return columnData;
    },
  },
  watch: {
    weeklyData(data) {
      console.log(data);
      let { processes, year, week, classesName, weekData } = data;
      let weekPlanData = processes;
      let weekPlan = [];
      let first = weekData[0].showDate;
      let last = weekData[6].showDate;
      this.weekData = weekData;
      this.classesName = classesName;
      this.date = {
        firstDate: first,
        lastDate: last,
        week,
        year
      };
      this.dialogVisible = true;
      this.templateData = data.d;
      console.log(weekPlanData);
      weekPlanData.forEach((item, index, arr) => {
        if (item === null) {
          item = [];
        }
        let _item = JSON.parse(JSON.stringify(item));
        let itemChidren = _item.children || _item.processes;
        if (!itemChidren) {
          itemChidren = [];
        }
        itemChidren = this.handlerLinkDataComeUp(itemChidren);
        itemChidren = this.handlerLinkDataDeleteRepeat(itemChidren);
        _item.children = [...itemChidren];
        weekPlan.push(_item);
      });
      this.weekPlanData = weekPlan;
    }
  }
};
</script>

<style lang="scss">
.weekly_plan {
  .el-dialog__body {
    padding: 0;
  }
  .weekly_plan_body{
    padding: 20px 40px;
    min-height: 400px;
    background: #617def;
    .weekly_plan_body_header {
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
      > div{
        flex-grow: 0;
        flex-shrink: 0;
      }
      .p {
        color: #fff;
        font-size: 14px;
        display: flex;
        align-items: center;
        img{
          margin-right: 5px;
        }
      }
      .wpbh_grade {
        width: 210px;
        display: flex;
        flex-flow: column-reverse;
      }
      .wpbh_title {
        color: #fff;
        font-size: 22px;
        text-align: center;
        p + p {
          margin-top: 10px;
        }
      }
      .wpbh_info {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        min-width: 210px;
        align-items: flex-end;
        .wpbh_info_download {
          margin-bottom: 10px;
          width: 100px;
          background: #3A3BE4;
        }
      }
    }

    .weekly_table {
      $width: 130px;
      width: $width * 8;
      .weekly_table_item {
        width: $width;
        box-sizing: border-box;
      }
      .weekly_table_header {
        display: flex;
        padding: 20px;
        background: #3a3bdb;
        border-radius: 30px 30px 0 0;
        .weekly_table_item {
          color: #fff;
          text-align: center;
          span + span {
            margin-top: 10px;
            display: block;
          }
       }
      }
      .weekly_table_body{
        .weekly_table_column {
          display: flex;
          box-sizing: border-box;
          .weekly_table_item {
            padding: 30px 10px;
            width: $width;
            border-right: 1px solid #E6E7EE;
            background: #fff;
            box-sizing: border-box;
            // flex-shrink: 0;
            &.weekly_item_first {
              background: #f4f6fd;
              .weekly_item_first_tiem {
                display: inline-block;
                margin-bottom: 10px;
                color: #000;
                font-size: 14px;
              }
              .weekly_item_first_title {
                color: #000;
                font-size: 16px;
              }
              color: #fff;
            }
            &.weekly_table_item_week {
              padding: 0;
              flex-grow: 7;
              .wtiw_row {
                display: flex;
                min-height: 105px;
                border-bottom: 1px solid #E6E7EE;
                .wtiw_row_item {
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                  width: $width;
                  border-right: 1px solid #E6E7EE;
                  @for $i from 2 through 7 {
                    &.wtiw_row_item_#{$i} {
                      width: $width * $i;
                    }
                  }
                  .wtiw_ri_course_name{
                    font-size: 18px;
                    font-weight: bold;
                  }
                  .wtiw_ri_activity_name{
                    padding-top: 8px;
                    font-size: 14px;
                  }
                }
              }
            }
          }
        }
      }

    }
  }
}
</style>

<template>
	<div class="course-wrapper plan" ><!--v-loading="loading"-->
		<div class="course-wrapper-title">
			<span>周计划</span>
		</div>

		<div class="course-wrapper-body">
			<div class="search_main search_main_week_project">
				<div>
          <el-select class="search_main_class" v-model="searchSchool" placeholder="选择学校" v-if="isSchoolMaster" @change="handleSchoolChange">
            <el-option
              v-for="checkSchool in schoolGroup"
              :key="checkSchool.label"
              :label="checkSchool.name"
              :value="checkSchool.label"
            ></el-option>
          </el-select>
          <el-select class="search_main_class" v-model="searchGrade" placeholder="选择年级" v-if="isSchoolMaster" @change="handleGradeChange">
            <el-option
              v-for="checkGrade in gradeGroup"
              :key="checkGrade.label"
              :label="checkGrade.name"
              :value="checkGrade.label"
            ></el-option>
          </el-select>
					<el-select
						class="search_main_class"
						v-model="searchClass"
						@change="handleSelectClass"
						placeholder="选择班级"
					>
						<el-option
							v-for="selectClass in selectClassBox"
							:key="selectClass.label"
							:label="selectClass.name"
							:value="selectClass.label"
						></el-option>
					</el-select>
					<el-select
						class="search_main_class"
						v-model="searchTemplateName"
            @change="changeSearchTemplate"
            clearable
						placeholder="选择模版">
						<el-option
							v-for="selectTemplate in selectTemplateBox"
							:key="selectTemplate.id"
							:label="selectTemplate.templateName"
							:value="selectTemplate.id"
						></el-option>
					</el-select>
				</div>
				<div class="date_picker_wrap">
					<el-button @click="handleWeekNum(-7)" type="text">上一周</el-button>
					<el-date-picker
            class="date_picker"
						v-model="searchWeekNum"
						@change="changeWeek"
						type="week"
						format="yyyy 第 WW 周"
						placeholder="选择周"
						value-format="yyyy-MM-dd"
						:clearable="false"
            firstDayOfWeek="1"
					>
					</el-date-picker>
					<el-button @click="handleWeekNum(7)" type="text">下一周</el-button>
				</div>
				<div style="width: 15.625vw;display: flex;justify-content: flex-end;align-items: center;">
					<el-button @click="handleButton('pushRecord')" type="text" v-if="isViewRecords && havePlan && isUse === '1' && selectTemplateBox.length > 0" icon="el-icon-refresh">推送记录</el-button>
					<!-- <el-button @click="handleButton">预览</el-button> -->
					<el-button @click="handleButton('download')" v-if="isDownload && havePlan && isUse === '1' && selectTemplateBox.length > 0" class="button_download">
            <i class="icon_download"></i>
          下载</el-button>

          <!-- <el-tooltip v-model="tooltipShow" class="tooltip_show" effect="light" content="内容已修改，点击推送" placement="bottom-end" :manual="true" v-if="isPush && (havePlan && isUse === '1' || !havePlan)"> -->
            <el-button @click="handleButton('push')" icon="el-icon-position" type="primary" v-if="isPush && (havePlan && isUse === '1') && selectTemplateBox.length > 0">推送</el-button>
          <!-- </el-tooltip> -->
          <!-- <el-tooltip class="tooltip_show" effect="light" content="根据当前所选模板对当前所选择周的教学计划数据进行重置" placement="bottom-end" v-if="isReset && havePlan && !isUse"> -->
            <el-button @click="handleButton('reset')" v-if="isReset && (havePlan && isUse === '0' || !havePlan) && selectTemplateBox.length > 0" type="primary">使用模板</el-button>
          <!-- </el-tooltip> -->
				</div>
			</div>
      <div class="header_publishTime" v-if="plan && (havePlan && isUse === '1') && selectTemplateBox.length > 0">
        <div class="tip" v-if="isNeedPush">（温馨提示：内容已修改待推送）</div>
        <div class="have_time"><span>最后推送时间：</span><span>{{ publishTime ? publishTime.substr(0,16) : '未推送' }}</span></div>
        <!-- <div class="have_time" v-else><span>推送状态：</span><span>未推送</span></div> -->
      </div>
      <schedule
        v-if="plan"
        :scheduleData="plan"
        @scheduleDataEdit="scheduleDataEdit"
        showStudent
        @activityDelete="activityDelete"
        :publishStatus="pushStatus"
        :havePlan="havePlan"
        :isUse="isUse"
      ></schedule>
      <div class="empty-content-panel" :style="{'height': '31.25vw'}" v-if="selectTemplateBox.length == 0">
        <img class="empty-img" :src="emptyImg" alt="" style="margin-bottom: 0.52083vw" />
        <div class="empty-title" style="margin-bottom: 0">暂无周计划内容</div>
      </div>
      <weeklyPlan v-if="weeklyData" :weeklyData="weeklyData"></weeklyPlan>
      <pushRecord :show="pushRecordShow"></pushRecord>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import tool from '../../utils/tool';
import schedule from '@/views/teachingProgram/components/schedule';
import weeklyPlan from '@/views/teachingProgram/components/weeklyPlan';
import pushRecord from '@/views/teachingProgram/components/pushRecord';
import emptyImg from '@/assets/img/empty-img.png';
import { getPermissionButton } from '@/utils/getUrlPermissionButtons';
export default {
  name: 'teachingProgram',
  components: {
    schedule,
    weeklyPlan,
    pushRecord
  },
  data() {
    return {
      loading: true,
      pageLoading: null, // 页面loading
      searchClass: '',
      searchClassName : '',
      searchTemplateName: '',
      searchWeekNum: '',
      // 所有班级
      selectClassBox: [],
      // 所有模版
      selectTemplateBox: [],
      plan: null,
      weeklyData: {},
      pushRecordShow: false,
      tooltipShow: false,
      _setTimeoutStatus: null,

      isEdit: false, // 编辑权限
      isDelete: false, // 删除权限
      isReset: false, // 数据重置权限
      isViewRecords: false, // 查看推送记录权限
      isDownload: false, // 下载权限
      isPush: false, // 推送权限
      permissionButtonList: [], // 权限列表
      pushStatus: false,
      publishTime: '',
      planId: null,
      isUse: '', //模板是否使用
      havePlan: false, //当前周是否有周计划
      isNeedPush: false, //是否需要推送

      updateTime: null, //编辑时间

      searchSchool: '',
      searchGrade: '',
      schoolGroup: [], //学校
      gradeGroup: [],// 年级
      isSchoolMaster: false, //是否园长

      emptyImg, // 空数据图片
    };
  },
  computed: {
    ...mapGetters(['permissions']),
    ...mapState({
      menuList: (state) => state.common.menuList,
    }),
  },
  watch: {
    permissionButtonList: {
      handler(val) {
        if (val) {
          this.isEdit = val.indexOf('teachingProgram:update') != -1;
          this.isDelete = val.indexOf('teachingProgram:delete') != -1;
          this.isReset = val.indexOf('teachingProgram:reset') != -1;
          this.isViewRecords = val.indexOf('teachingProgram:records') != -1;
          this.isDownload = val.indexOf('teachingProgram:download') != -1;
          this.isPush = val.indexOf('teachingProgram:push') != -1;
        }
      },
      deep: true,
    },
    menuList: {
      handler(val) {
        if (val) {
          this.getPermissionButton(this.$route.path, (data) => {
            this.permissionButtonList = data;
          });
        }
      },
      deep: true,
    },
  },
  async created() {
    this.pageLoading = this.$loading({
      lock: true,
      fullscreen: true,
      text: '加载中',
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.7)'
    });
    // menulist一直在时这个方法不可缺少
    this.getPermissionButton(this.$route.path, (data) => {
      this.permissionButtonList = data;
    });
    let date = tool.formatTime(new Date);
    let self = this;
    this.searchWeekNum = date;
    let schoolResult = await this.getSchoolInfo()
    console.log(schoolResult)
    if(schoolResult.flag){
      this.isSchoolMaster = schoolResult.flag
      this.searchSchool = schoolResult.list[0].label
      let gradeResult = await this.getGradeInfo(schoolResult.list[0].label)
      if(gradeResult.length > 0){
        this.searchGrade = gradeResult[0].label
        let classResult = await this.getSearchClass(gradeResult[0].label)
        if(classResult.length > 0){
          this.searchClass = classResult[0].label;
          this.searchClassName = classResult[0].name;
          await this.handleSelectClass();
          this.$nextTick(() => {
            this.recursionClassTemplate(JSON.parse(JSON.stringify(classResult)));
            this.pageLoading.close()
          });
        }
      }
    } else {
      let classResult = await this.getSearchClass()
      if(classResult.length > 0){
        this.searchClass = classResult[0].label;
        this.searchClassName = classResult[0].name;
        await this.handleSelectClass();
        this.$nextTick(() => {
          this.recursionClassTemplate(JSON.parse(JSON.stringify(classResult)));
          this.pageLoading.close()
        });
      } else {
        this.pageLoading.close()
      }
    }
  },
  methods: {
    getPermissionButton,
    //获取班级list
    async getSearchClass(gradeId){
      let classResult = null
      let functionName = ''
      let param = {}
      if(this.isSchoolMaster){
        functionName = 'getClassInfo'
        param.label = gradeId
      } else {
        functionName = 'searchStudentsClass'
      }
      await this.$api[functionName](param).then(async result => {
        let { code, data } = result.data;
        if (code) {
          classResult = []
          return;
        }
        data.map((item) => {
          item.label = item.label ? item.label : item.classLabel
          item.name = item.name ? item.name : item.className
        })
        this.selectClassBox = data
        classResult = data
      }).catch(err => {
        classResult = []
      });
      return classResult
    },
    //根据学校获取年级list
    async getGradeInfo(schoolId,callback){
      let gradeResult = null
      let param = { label: schoolId }
      await this.$api.getGradeInfo(param).then(result => {
        let { code, data } = result.data;
        if (code) {
          gradeResult = []
          return;
        }
        this.gradeGroup = data
        gradeResult = data
      }).catch(err => {
        gradeResult = []
      });
      return gradeResult
    },
    //获取登录人是否为园长以及为园长是的学校list
    async getSchoolInfo(callback){
      let schoolResult = null
      await this.$api.getSchoolInfo({}).then(result => {
        let { code, data } = result.data;
        if (code) {
          schoolResult = {}
          return;
        }
        let resultList = []
        console.log(data.masterSchoolList)
        data.masterSchoolList.map((item) => {
          let schoolInfo = {}
          for(let info in item){
            schoolInfo.label = info
            schoolInfo.name = item[info]
          }
          resultList.push(schoolInfo)
        })
        this.schoolGroup = resultList;
        console.log(resultList)
        schoolResult = { flag: data.isSchoolMaster == '1' ? true : false, list: resultList }
      }).catch(err => {
        schoolResult = {}
      });
      return schoolResult
    },
    //学校变化事件
    async handleSchoolChange(val){
      this.searchSchool = val
      this.searchGrade = ''
      this.searchClass = ''
      this.searchClassName = ''
      this.selectClassBox = []
      this.selectTemplateBox = []
      this.plan = null
      this.planId = null
      this.havePlan = true
      this.isUse = ''
      let gradeResult = await this.getGradeInfo(val)
      if(gradeResult.length > 0){
        this.searchGrade = gradeResult[0].label
        let classResult = await this.getSearchClass(gradeResult[0].label)
        if(classResult.length > 0){
          this.searchClass = classResult[0].label;
          this.searchClassName = classResult[0].name;
          await this.handleSelectClass();
          this.$nextTick(() => {
            this.recursionClassTemplate(JSON.parse(JSON.stringify(classResult)));
          });
        }
      }
    },
    //年级变化事件
    async handleGradeChange(val){
      this.searchGrade = val
      this.searchClass = ''
      this.searchClassName = ''
      this.selectClassBox = []
      this.selectTemplateBox = []
      this.plan = null
      this.planId = null
      this.havePlan = true
      this.isUse = ''
      let classResult = await this.getSearchClass(gradeResult[0].label)
      if(classResult.length > 0){
        this.searchClass = classResult[0].label;
        this.searchClassName = classResult[0].nale;
        await this.handleSelectClass();
        this.$nextTick(() => {
          this.recursionClassTemplate(JSON.parse(JSON.stringify(classResult)));
        });
      }
    },
    async recursionClassTemplate(classData, index) {
      if (index === undefined)  {
        index = 0;
      }
      let _classes = classData[0];
      this.searchClass = _classes.label;
      this.searchClassName = _classes.name;
      let result = await this.searchTeachingPrograms();
      if (result.techPlanDayProcess) {
        return;
      } else {
        if (this.selectTemplateBox[index]) {
          this.searchTemplateName = this.selectTemplateBox[index].id;
          result = await this.searchTeachingPrograms();
          if (result.techPlanDayProcess) {
            this.havePlan = false
            return;
          } else {
            // this.pushStatus = false
            index++;
            this.searchTemplateName = this.selectTemplateBox[index].id;
          }
        } else {
          classData.shift();
          _classes = classData[0];
          if (_classes) {
            index = 0;
            this.searchClass = _classes.label;
            this.searchClassName = _classes.name;
            await this.handleSelectClass();
          } else {
            return;
          }
        }
      }
      return this.recursionClassTemplate.call(this, classData, index);
    },

    async handleChangeTemplate(index) {
      if (index === undefined)  {
        index = 0;
      }
      let result = await this.searchTeachingPrograms();
      if (result.techPlanDayProcess) {
        this.pageLoading.close()
        return;
      } else {
        if (this.selectTemplateBox[index]) {
          this.searchTemplateName = this.selectTemplateBox[index].id;
          result = await this.searchTeachingPrograms();
          if (result.techPlanDayProcess) {
            this.pageLoading.close()
            return;
          } else {
            index++;
            this.searchTemplateName = this.selectTemplateBox[index].id;
          }
        } else {
          this.pageLoading.close()
          return;
        }
      }
      return this.handleChangeTemplate.call(this, index);
    },

    async handleButton(handleName) {
      let { year, week:weekNum, week:week } = tool.getYearWeek(this.searchWeekNum);
      let weekDate = tool.formatTime(this.searchWeekNum,1)
      console.log(week)
      switch (handleName) {
        case 'pushRecord': {
          this.pushRecordShow = false;
          this.$nextTick(() => {
            this.pushRecordShow = true;
          });
          break;
        }
        case 'reset': {
          // let templateId = this.searchTemplateName
          let info = this.selectTemplateBox.find((item) => { return item.isUse === '1' })
          if(info){
            const h = this.$createElement;
            this.$confirm(
              h('p', null, [
                h('span', { style: 'color: rgba(0, 0, 0, 0.85);font-size: 16px;font-weight: 500;', }, '您正在使用'),
                h('span', { style: 'color: red;font-size: 16px;font-weight: 500;', }, '《'+(info.templateName.length > 10 ? (info.templateName.substr(0,10)+'...') : info.templateName)+'》'),
                h('span', { style: 'color: rgba(0, 0, 0, 0.85);font-size: 16px;font-weight: 500;', }, '模板，切换模板后本周已编辑内容将被'),
                h('span', { style: 'color: red;font-size: 16px;font-weight: 500;', }, '清空'),
                h('span', { style: 'color: rgba(0, 0, 0, 0.85);font-size: 16px;font-weight: 500;', }, '，是否继续'),
                // h('div', { style: 'color: rgba(0, 0, 0, 0.65);font-size: 14px;margin-top: 10px', }, '是否确定撤销该推送记录，撤销后，需要重新编辑推送。'),
              ]), '提示', { confirmButtonText: '继续使用', cancelButtonText: '取消', iconClass: 'el-icon-question', type: 'warning', customClass: 'warningMessage', }
            ).then(() => {
              this.pageLoading = this.$loading({
                lock: true,
                fullscreen: true,
                text: '操作中',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
              });
              let { planId } = this;

              this.$api.changeTeachingPrograms({ year, weekNum, planId, weekDate, classesLabel:this.searchClass, planTemplateId: this.searchTemplateName }).then(async(result) => {
                let { code } = result.data;
                if (code) {
                  this.$message.error('切换模板失败');
                  return;
                }
                this.havePlan = false
                this.isUse = ''
                this.isNeedPush = false
                // this.pushStatus = false
                await this.handleSelectClass();
                this.searchTemplateName = templateId
                this.$nextTick(() => {
                  this.pageLoading = this.$loading({
                    lock: true,
                    fullscreen: true,
                    text: '加载中',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                  });
                  this.searchTeachingPrograms()
                })
                this.$message.success('切换模板成功');
              })
              .catch(err => {})
              .finally(() => {
                this.pageLoading.close()
              });
            }).catch(() => {});
          } else {
            this.pageLoading = this.$loading({
              lock: true,
              fullscreen: true,
              text: '操作中',
              spinner: 'el-icon-loading',
              background: 'rgba(0, 0, 0, 0.7)'
            });
            let { planId } = this;

            this.$api.changeTeachingPrograms({ year, weekNum, planId, weekDate, classesLabel:this.searchClass, planTemplateId: this.searchTemplateName }).then(async(result) => {
              let { code } = result.data;
              if (code) {
                this.$message.error('使用模板失败');
                return;
              }
              this.havePlan = false
              this.isUse = ''
              this.isNeedPush = false
              // this.pushStatus = false
              await this.handleSelectClass();
              this.searchTemplateName = templateId
              this.$nextTick(() => {
                this.pageLoading = this.$loading({
                  lock: true,
                  fullscreen: true,
                  text: '加载中',
                  spinner: 'el-icon-loading',
                  background: 'rgba(0, 0, 0, 0.7)'
                });
                this.searchTeachingPrograms()
              })
              this.$message.success('使用模板成功');
            })
            .catch(err => {})
            .finally(() => {
              this.pageLoading.close()
            });
          }
          
          break;
        }
        case 'push': {
          this.pageLoading = this.$loading({
            lock: true,
            fullscreen: true,
            text: '推送中',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          });
          let planTemplateId =  this.searchTemplateName || this.plan.d.id;
          let weekDate = this.searchWeekNum + ' 00:00:00';

          this.$api.pushTeachingPrograms({
            year,
            weekNum,
            classesLabel: this.searchClass,
            planTemplateId,
            weekDate,
          })
            .then(async(result) => {
              console.log(result);
              let { code } = result.data;
              if (code) {
                this.$message.error('推送失败');
                return;
              }
              this.$message.success('推送成功');
              this.havePlan = false
              this.isUse = ''
              this.isNeedPush = false
              // this.pushStatus = false
              await this.handleSelectClass();
              this.$nextTick(() => {
                this.pageLoading = this.$loading({
                  lock: true,
                  fullscreen: true,
                  text: '加载中',
                  spinner: 'el-icon-loading',
                  background: 'rgba(0, 0, 0, 0.7)'
                });
                this.searchTeachingPrograms()
              })
            })
            .catch(err => {
              this.$message.error(err.message);
            })
            .finally(() => {
              this.pageLoading.close()
            });
          break;
        }
        case 'download': {
          this.$nextTick(() => {
            this.weeklyData = Object.assign({
              dateRange: this.dayToDate(),
              year,
              week: weekNum,
              weekData: this.getToWeekDate(this.searchWeekNum),
              classesName: this.searchClassName,
            }, this.plan);
          });
        }
      }
    },
    dayToDate() {
      let date = this.searchWeekNum;
      let day = new Date(date).getUTCDay();
      let nowDate = new Date(date);
      let _date;
      let dateJson = {};
      if (day === 0) {
        nowDate = nowDate.setDate(nowDate.getDate() + 6);
      }
      for (let i = day; i > 0; i--) {
        _date = nowDate.setDate(nowDate.getDate() - 1);
        nowDate = tool.formatTime(_date);
        dateJson.first = nowDate;
        nowDate = new Date(nowDate);
        dateJson.last = tool.formatTime(nowDate.setDate(nowDate.getDate() + 6));
      }
      return dateJson;
    },
    dialogDataSave(scope) {
      console.log(scope);
    },
    /**
     * 获取班级
     */
    async handleSelectClass(value) {
      let { searchClass } = this;
      let _result = null;
      this.searchTemplateName = '';
      this.planId = null
      this.updateTime = null
      this.plan = null
      if(value !== undefined){
        this.selectClassBox.forEach(item => {
          if (item.label === value) {
            this.searchClassName = item.className;
          }
        });
      }
      let date = this.searchWeekNum;
      let week = tool.getYearWeek(date);
      let year = date.split('-')[0];
      let params = {
        year,
        weekNum: week.week,
        classesLabel: this.searchClass,
      };
      // await this.searchTeachingPrograms();
      await this.$api
        .searchTeachingProgramsGradeTemplate(params)
        .then(result => {
          let { code, data, msg } = result.data;
          if (code) {
            this.$message.error(msg);
            return;
          }
          this.havePlan = false
          data.map((item) => {
            if(item.isUse === '1'){
              item.templateName = item.templateName+"(使用中)"
              this.searchTemplateName = item.id
              this.havePlan = true
              this.isUse = item.isUse
            } else {
              item.templateName = item.templateName
            }
          })
          _result = data;
          this.selectTemplateBox = data;
        })
        .catch(err => {});
        let planResult = await this.searchTeachingPrograms();
        if(planResult.techPlanDayProcess && this.havePlan){
          this.updateTime = planResult.updateDate
          this.planId = planResult.techPlanDayProcess[0].planId
        } else {
          this.updateTime = null
          this.planId = null
          if(value !== undefined){
            this.$nextTick(() => {
              this.handleChangeTemplate()
            })
          }
        }
      return _result;
    },
    searchClassStudent(classesLabel) {
      this.$api
        .searchStudents({
          classesLabel,
        })
        .then(result => {
          console.log(result);
        })
        .catch(err => {});
    },
    /**
		 * 左右变换周数
		 * @param {number} num
		 * @return {*}
		 */
    async handleWeekNum(num) {
      let date = new Date(this.searchWeekNum);
      date = date.setDate(date.getDate() + num);
      this.searchWeekNum = tool.formatTime(date);
      this.searchTemplateName = ''
      this.havePlan = false
      this.isUse = ''
      this.isNeedPush = false
      await this.handleSelectClass();
      this.pageLoading = this.$loading({
        lock: true,
        fullscreen: true,
        text: '加载中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.$nextTick(() => {
        this.handleChangeTemplate()
      })
    },
    /**
     * 查询教学计划数据
     * @param {*}
     * @return {*}
     */
    async searchTeachingPrograms() {
      this.pushStatus = false;
      let date = this.searchWeekNum;
      let week = tool.getYearWeek(date);
      let year = date.split('-')[0];
      let data = {
        year,
        weekNum: week.week,
        classesLabel: this.searchClass,
      };
      if (this.searchTemplateName) {
        data.planTemplateId = this.searchTemplateName;
      }
      let _result = null;
      await this.$api
        .searchTeachingProgramsGradeTemplateDetails(data)
        .then(result => {
          let { code, data, msg } = result.data;
          if (code) {
            this.$message.success(msg);
            return;
          }
          let { techPlanDayProcess, techPlanTemplate } = data;
          _result = data;
          // techPlanDayProcess.forEach(item => {
          //   console.log(item)
          //   if (item.planId) {
          //     this.planId = item.planId;
          //   } else {
          //     this.planId = null
          //   }
          // });

          // let classStudentInfo = this.selectClassBox.find((item) => { return item.classLabel == this.searchClass })
          // techPlanDayProcess.map((item) => {
          //   if(item.children && item.children.length > 0){
          //     item.children.map((info) => {
          //       info.map((result) => {
          //         let exitNum = 0
          //         if(result.techPlanTeachStudent && result.techPlanTeachStudent.length > 0){
          //           result.techPlanTeachStudent.map((studentInfo) => {
          //             let index = classStudentInfo. studentInfo.studentLabel 
          //           })
          //         } else {
          //           result.showStudentFlag = false
          //         }
          //       })
          //     })
          //   }
          // })

          if (techPlanTemplate) {
            techPlanDayProcess.map(item => {
              item.processes = item.children;
              return item;
            });
            this.plan = null;
            this.refreshPublishStatus(year,week.week)
            this.$nextTick(() => {
              this.plan = {
                d: techPlanTemplate,
                processes: techPlanDayProcess,
                student: {
                  classesLabel: this.searchClass,
                  planDay: this.searchWeekNum
                }
              };
            });
          } else {
            this.plan = null;
          }
        })
        .catch(err => {
        })
        .finally((data) => {
          this.pageLoading.close()
        });
      return _result;
    },
    /**
     * 刷新状态
     * @param {*}
     * @return {*}
     */
    async refreshPublishStatus(year,week){
      let pushInfo = await this.getPublishStatus(year,week,this.searchClass)
      this.pushStatus = pushInfo ? true : false
      this.publishTime = pushInfo ? tool.formatTime(new Date(pushInfo.createTime),1) : ''
      if(this.havePlan && this.isUse === '1' && pushInfo){
        if(this.updateTime && new Date(this.updateTime).getTime() > new Date(pushInfo.createTime).getTime()){
          this.isNeedPush = true
        } else {
          this.isNeedPush = false
        }
      }
    },
    /**
     * 查询教学计划是否存在
     * @param {*}
     * @return {*}
     */
    async getPublishStatus(year,weekNum,classesLabel){
      let info = null
      let params = {
        year,
        weekNum,
        classesLabel
      }
      await this.$api.searchTeachingProgramsStatus(params).then(result => {
        let { code, data, msg } = result.data;
        if (code) {
          this.$message.error(msg);
          info = null
          return;
        }
        if(data.id){
          info = data
        } else {
          info = null
        }
      }).catch(err => {
        info = null
      });

      return info
    },
    async changeWeek(value) {
      console.log(value);
      this.searchWeekNum = tool.formatTime(value);
      this.searchTemplateName = ''
      this.havePlan = false
      this.isUse = ''
      await this.handleSelectClass();
      this.pageLoading = this.$loading({
        lock: true,
        fullscreen: true,
        text: '加载中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.$nextTick(() => {
        this.handleChangeTemplate()
      })
    },
    confirm(data) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          data.success && data.success(data.data);
        })
        .catch(() => {
          data.fail && data.fail();
        });
    },
    /**
     * 选择模版
     * @param {string|number} value id
     */
    changeSearchTemplate(value) {
      let temlpateInfo = this.selectTemplateBox.find((item) => { return item.id == value })
      this.isUse = temlpateInfo.isUse
      this.isNeedPush = false
      this.searchTemplateName = value
      this.$nextTick(() => {
        this.pageLoading = this.$loading({
          lock: true,
          fullscreen: true,
          text: '加载中',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        this.searchTeachingPrograms();
      })
    },
    /**
     * 教学计划流程组件触发
     * @param {*} data
     * @return {*}
     */
    scheduleDataEdit(row) {
      this.pageLoading = this.$loading({
        lock: true,
        fullscreen: true,
        text: '操作中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let { data, link, dataType } = row;
      let date = this.searchWeekNum;
      let yearWeek = tool.getYearWeek(date);
      // let _data = Object.assign({}, data);
      let { activityFrom, fromType, resourceId, courseName, activityName, activityImg, remark, bgColor, techPlanTeachStudent, uniteCode, allStudent } = data;
      let form = {
        year:yearWeek.year,
        weekNum:yearWeek.week,
        planId:link.planId || null,
        planDayProcessId:link.id || '',
        weekDate: tool.formatTime(this.searchWeekNum, 1),
        activityFrom,
        fromType,
        resourceId,
        courseName,
        activityName,
        activityImg,
        remark,
        bgColor,
        uniteCode,
        planDays:data.date.join(),
        planTemplateId:this.searchTemplateName,
        techPlanTeachStudent,
        allStudent,
        classesLabel: this.searchClass
      };
      if (dataType === 'update') {
        form.id = data.id;
      } else {
        delete form.id;
      }
      this.$api
        .updateTeachingPrograms(form)
        .then(async(result) => {
          console.log(result);
          let { code, data, msg } = result.data;
          if (code) {
            this.$message.error(msg);
            return;
          }
          this.$message.success(data);
          // this.searchTemplateName = ""
          this.havePlan = false
          this.isUse = ''
          this.isNeedPush = false
          await this.handleSelectClass();
          this.pageLoading = this.$loading({
            lock: true,
            fullscreen: true,
            text: '加载中',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          });
          this.$nextTick(() => {
            this.handleChangeTemplate()
          })
          // this.pushStatus = true;
          // this.tooltipShow = true;
          // this.isNeedPush = true
          // this._setTimeoutStatus && clearTimeout(this._setTimeoutStatus);
          // setTimeout(() => {
          //   this.tooltipShow = false;
          // }, 5000);
        })
        .catch(err => {
          this.$message.error(err.message);
        })
        .finally(() => {
          this.pageLoading.close()
        });
    },
    getToWeekDate(date) {
      let _date = new Date(date);
      let _dateDay = _date.getDay();
      let oneDate;
      let weekData = [];
      let toDay = new Date().getDay();
      let weekText = [
        { text: '周一', eText: 'Monday' },
        { text: '周二', eText: 'Tuesday' },
        { text: '周三', eText: 'Wednesday' },
        { text: '周四', eText: 'Thursday' },
        { text: '周五', eText: 'Friday' },
        { text: '周六', eText: 'Saturday' },
        { text: '周日', eText: 'Sunday' },
      ];
      if (_dateDay === 0) {
        _dateDay = 6;
      }
      oneDate = new Date(_date).getDate() - _dateDay + 1;
      oneDate = new Date(_date).setDate(oneDate);
      oneDate = tool.formatTime(oneDate);
      for (let i = 0; i < 7; i++) {
        let dateData = {
          date: oneDate,
          day: new Date(oneDate).getDay(),
        };
        dateData.showDate = dateData.date.split('-');
        dateData.showDate.shift();
        dateData.showDate = dateData.showDate.join('.');
        dateData.className = dateData.day === toDay ? 'schedule_box_item schedule_box_item_acitve' : 'schedule_box_item';
        weekData.push(dateData);
        oneDate = new Date(oneDate).setDate(new Date(oneDate).getDate() + 1);
        oneDate = tool.formatTime(oneDate);
      }
      weekData.map((item, index) => {
        item.text = weekText[index].text;
        item.eText = weekText[index].eText;
      });
      return weekData;
    },
    activityDelete(scope) {
      console.log(scope)
      let date = this.searchWeekNum;
      let yearWeek = tool.getYearWeek(date);
      let param = {
        year: yearWeek.year,
        weekNum: yearWeek.week,
        weekDate: tool.formatTime(this.searchWeekNum, 1),
        planTemplateId:this.searchTemplateName,
        classesLabel: this.searchClass,
        uniteCode: scope.uniteCode
      }
      const h = this.$createElement;
      this.$confirm(
        h('p', null, [
          h('span', { style: 'color: rgba(0, 0, 0, 0.85);font-size: 16px;font-weight: 500;', }, '确定要删除活动【'+scope.courseName+'】？'),
          h('div', { style: 'color: rgba(0, 0, 0, 0.65);font-size: 14px;margin-top: 10px', }, '删除后，点击日期区域可重新添加'), /// 移除后，可点击【添加课程】中重新添加
        ]), '提示', { confirmButtonText: '确定', cancelButtonText: '取消', iconClass: 'el-icon-question', type: 'warning', customClass: 'warningMessage', }
      ).then(() => {
        this.pageLoading = this.$loading({
          lock: true,
          fullscreen: true,
          text: '删除中',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        this.$api.delPlanDetail(param).then(res => {
          if (res.data.code === 0) {
            this.$message({ message: '删除活动成功', type: 'success', duration: 1500, onClose: async() => {
              this.havePlan = false
              this.isUse = ''
              await this.handleSelectClass();
              this.pageLoading = this.$loading({
                lock: true,
                fullscreen: true,
                text: '加载中',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
              });
              this.$nextTick(() => {
                this.handleChangeTemplate()
              })
              // this.pushStatus = true;
              // this.tooltipShow = true;
              this.isNeedPush = true
              // this._setTimeoutStatus && clearTimeout(this._setTimeoutStatus);
              // setTimeout(() => {
              //   this.tooltipShow = false;
              // }, 5000);
            } });
          } else {
            this.$message.error(res.data.msg);
          }
        }).finally(() => {
         this.pageLoading.close()
        });;
      }).catch(() => {});
    },
  }
};
</script>

<style lang="scss" >
@import '@/assets/css/common_c.scss';
.course-wrapper{
  &.plan{
    width: 100%;
    height: 100%;
    .course-wrapper-body{
      width: calc(100% - 40px);
      height: calc(100% - 101px);
      .header_publishTime{
        width: 100%;
        height: 30px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        font-size: 14px;
        font-weight: 400;
        color: #595959;
        .tip{
          margin-right: 20px;
          color: rgba(89,126,247,0.8);
        }
      }
      .schedule_wrap{
        margin-top: 0;
        height: calc(100% - 86px);
        .schedule{
          padding-top: 0;
          height: calc(100% - 40px);
          max-height: calc(100% - 40px);
        }
      }
    }
  }
}
.search_main {
	$width: 180px;
	&.search_main_week_project {
		display: flex;
		justify-content: space-between;
    flex-wrap: wrap;
	}

	.search_main_title,
	.search_main_class,
	.search_main_date {
		margin-right: 20px;
    margin-bottom: 20px;
		width: $width;
	}
}
.table_data {
	.table_data_button_divider {
		display: inline-block;
		padding: 0 10px;
	}
}
.pagination {
	margin-top: 20px;
}
.el-tooltip__popper.is-light{
  border: 0;
  box-shadow: 0 0 4px #00000060;
  &[x-placement^=bottom] .popper__arrow {
    border-bottom-color: #00000040;
  }
}
.date_picker_wrap {
  .date_picker {
    margin: 0 20px;
    width: 160px;
    .el-input__inner {
      width: 160px;
      padding-right: 16px;
    }
  }
}
.button_download {
  color: #597EF7;
}
.icon_download {
  display: inline-block;
  width: 14px;
  height: 12px;
  background: url(../../assets/img/yundownload.png);
  background-size: cover;
  transform: translateY(2px);
}
.el-image-viewer__wrapper {
  .el-image-viewer__canvas {
    transform: scale(.8);
  }
}
</style>

<template>
  <el-dialog
    title="推送记录"
    :visible.sync="dialogVisible"
    width="width"
    :before-close="dialogBeforeClose">
    <div>
      <el-table
        :data="tableData"
      >
        <el-table-column
          type="index"
          label="序号"
          width="5.20833vw"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="templateName"
          label="计划模版名称"
          width="width"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="dateTimeStr"
          label="日期"
          width="width"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="userName"
          label="推送人"
          width="width"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="createTime"
          label="推送时间"
          width="width"
          align="center"
        >
        </el-table-column>
      </el-table>
      <div class="footer" align="right">
        <el-pagination
          class="pagination"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          layout="total,sizes,prev,pager,next,jumper"
          :total="pagination.total"
          :page-size="pagination.size"
          background
        >
        </el-pagination>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dialogVisible: false,
      tableData: [],
      pagination: {
        current: 1,
        size: 10,
        total: 1
      }
    };
  },
  created() {
    this.searchPushRecord();
  },
  methods: {
    dialogBeforeClose() {
      this.dialogVisible = false;
    },
    handleSizeChange(value) {
      this.pagination.size = value;
      this.searchPushRecord();
    },
    handleCurrentChange(value) {
      this.pagination.current = value;
      this.searchPushRecord();
    },
    searchPushRecord() {
      let { current, size } = this.pagination;
      this.$api.searchPushRecord({
        current,
        size
      })
        .then(result => {
          let { code, data } = result.data;
          if (code) {
            this.$message.error(data);
            return;
          }
          let { list, total } = data;
          list.map(item => {
            let date = item.weekYear.split('-');
            let year = date.pop();
            let week = date.pop();
            item.dateTimeStr = `${ year }年第${ week }周`;
          });
          this.tableData = list;
          this.pagination.total = total;
        // this.pagination.size = pages;
        })
        .catch(err => {
          this.$message.error(err.message);
        });
    }
  },
  watch: {
    show(v) {
      if (v) {
        this.dialogVisible = v;
      }
    }
  }
};
</script>

<style lang="scss">
</style>
